import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { getTokenInfo } from "../utils/Guard";

import { GetConferenceByCode } from "../store/conferences-store/conference-actions";
import { GetDiscountAvailable } from "../store/discount-store/discount-actions";
import { CreateOrder } from "../store/orden-store/orden-actions";
import { uiActions } from "../store/ui-slice";

import "./HomeContent.css";
import { Card } from "primereact/card";
import Loading from "./UI/Loading";

let getDiscount = true;

const HomeContent = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const conference = useSelector((state) => state.conference.conference);
  const discount = useSelector((state) => state.discount.discount);
  const notification = useSelector((state) => state.ui.notification);
  const [isWaiting, setIsWaiting] = useState(false);
  const userInfo = getTokenInfo();

  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [subTotal, setsubTotal] = useState(0);
  const [discountVal, setDiscount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    if (conference && conference.id === 0) {
      dispatch(GetConferenceByCode(params.conferenceCode));
    }
  }, [conference, dispatch, params.conferenceCode]);

  useEffect(() => {
    if (conference && conference.id > 0) {
      setsubTotal(count * conference.price);
      setTotal(subTotal - discountVal);
    }
  }, [conference, count, discountVal, subTotal]);

  useEffect(() => {
    if (getDiscount === true) {
      getDiscount = false;
      dispatch(
        GetDiscountAvailable(userInfo.Email, count, params.conferenceCode)
      );
    }
  }, [count, discount, dispatch, params.conferenceCode, userInfo.Email]);

  useEffect(() => {
    if (discount && discount.id > 0 && discount.grupal === true && count >= 3) {
      if (discount.percentage === true) {
        setDiscount(
          count * conference.price * (discount.discountPercentage / 100)
        );
      }
      if (discount.nominal === true) {
        setDiscount(count * discount.discountNominal);
      }
    }
    if (discount && discount.id > 0 && discount.grupal === true && count < 3) {
      setDiscount(0);
    }
    if (discount && discount.id > 0 && discount.grupal === false) {
      if (discount.percentage === true) {
        setDiscount(
          count * conference.price * (discount.discountPercentage / 100)
        );
      }
      if (discount.nominal === true) {
        setDiscount(count * discount.discountNominal);
      }
    }
  }, [conference.price, count, discount, total]);

  useEffect(() => {
    if (window.innerWidth <= 430 && !isMobile) {
      setIsMobile(true);
    }
  }, []);
  let discountInfo = "";
  if (discount && discount.id > 0 && discount.nominal === true) {
    discountInfo =
      "Descuento de " +
      USDollar.format(discount.discountNominal) +
      " por inscrito";
  }
  if (discount && discount.id > 0 && discount.percentage === true) {
    discountInfo =
      "Descuento de " + discount.discountPercentage + "% por inscrito";
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (count === 0) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Debe agregar al menos un inscrito",
        })
      );
    } else {
      const orden = {
        userName: userInfo.Email,
        conferecenCodeName: params.conferenceCode,
        discountCode: discount.discountCode,
        amount: subTotal,
        quantityOfUsers: count,
      };
      setIsWaiting(true);
      dispatch(CreateOrder(userInfo.Email, orden));
      setTimeout(function () {
        window.location.reload();
      }, 5000);
    }
  };

  return (
    <>
      {isWaiting && <Loading visible={isWaiting} />}
      {!isWaiting && (
        <>
          <div className="homeContentHeader">
            <div className="homeContentTitle">
              <span>Completa tu inscripción</span>
            </div>
          </div>
          <div className="card flex justify-content-center ">
            <form onSubmit={onSubmitHandler}>
              <div className="formgrid grid ">
                <div className="field col-12 md:col-12">
                  <Card className="bodyContent">
                    <div className="formgrid grid ">
                      <div className="field col-12 md:col-6"></div>
                      <div className="field col-12 md:col-6">
                        <div className="formgrid grid">
                          <div className="field col-12 md:col-12 priceText">
                            <span className="">
                              USD$ {USDollar.format(conference.price)}{" "}
                            </span>
                            <Button
                              type="button"
                              className="less"
                              icon="pi pi-minus"
                              onClick={() => {
                                if (count > 0) {
                                  getDiscount = true;
                                  setCount(count - 1);
                                }
                              }}
                            />
                            <span className="countParticipants">{count}</span>
                            <Button
                              type="button"
                              className="plus"
                              icon="pi pi-plus"
                              onClick={() => {
                                getDiscount = true;
                                setCount(count + 1);
                              }}
                            />
                          </div>
                          <div className="field col-12 md:col-6"></div>
                        </div>
                      </div>
                      {!isMobile && (
                        <div className="field col-12 md:col-12">
                          <div className="formgrid grid ">
                            <div
                              className="field col-12 md:col-6"
                              style={{
                                marginTop: "-100px",
                                textAlign: "Center",
                              }}
                            >
                              <h1>{conference.informativeTitle}</h1>
                              <h2 style={{ marginBottom: "50px" }}>
                                {" "}
                                {conference.informativeBody}
                              </h2>
                              <p className="descriptionConference ">
                                <p>
                                  En Aula ABA ampliamos nuestra su oferta con
                                  acceso ilimitado a más de 12,000 cursos en
                                  línea de primer nivel.
                                </p>
                                <p>
                                  Descubre una amplia variedad de proyectos
                                  prácticos y programas de certificación
                                  diseñados para prepararte para el mundo
                                  laboral, todo incluido en tu suscripción.
                                </p>
                                <p>
                                  Obtén acceso a videos de los cursos,
                                  especializaciones y certificados
                                  profesionales, impartidos por destacados
                                  instructores de universidades y empresas
                                  líderes.
                                </p>
                                <p>
                                  Acceso más de 12,000 cursos y certificaciones
                                  de universidades locales e internacionales
                                  reconocidas. Aprenderás habilidades y
                                  herramientas relevantes para el trabajo con
                                  más de 1000 proyectos aplicados y laboratorios
                                  prácticos de expertos de la industria.
                                </p>
                                <p>
                                  Disfrutarás de la máxima flexibilidad para
                                  lograr el equilibrio entre vida personal y
                                  laboral y aprender a su propio ritmo.
                                </p>
                              </p>
                            </div>
                            <div className="field col-12 md:col-6 totalPayment">
                              <div className="formgrid grid ">
                                <div className="field col-12 md:col-2 totalpaymentInfo">
                                  <span className="count">{count}</span>
                                </div>
                                {discountInfo !== "" && (
                                  <div className="field col-12 md:col-6">
                                    <div className="discount">
                                      USD$ {USDollar.format(discountVal)}
                                    </div>
                                    <div className="discountText">
                                      {discountInfo}
                                    </div>{" "}
                                  </div>
                                )}
                                <div className="field col-12 md:col-4 totalpaymentInfo ">
                                  <span className="subTotal">
                                    USD$ {USDollar.format(subTotal)}
                                  </span>
                                </div>
                                <div className="field col-12 md:col-12 linePaymentInfo"></div>
                                <div className="field col-12 md:col-4 totalInfo">
                                  <span>TOTAL</span>
                                </div>
                                <div className="field col-12 md:col-4 h1text ">
                                  <span className="">
                                    USD$ {USDollar.format(total)}
                                  </span>
                                </div>
                                <div className="field col-12 md:col-12 ">
                                  <Button
                                    label="Continuar"
                                    className="paymentInfoButton"
                                    icon={
                                      notification &&
                                      notification.status === "pending"
                                        ? "pi pi-spin pi-spinner"
                                        : ""
                                    }
                                    disabled={
                                      notification &&
                                      notification.status === "pending"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {isMobile && (
                        <div className="field col-12 md:col-12">
                          <div className="formgrid grid ">
                            <div className="field col-12 md:col-6 totalPayment">
                              <div className="formgrid grid ">
                                <div className="field col-12 md:col-2 totalpaymentInfo">
                                  <span className="count">{count}</span>
                                </div>
                                <div className="field col-12 md:col-12 totalpaymentInfo">
                                  <div className="formgrid grid ">
                                    {discountInfo !== "" && (
                                      <>
                                        <div className="field col-12 md:col-6">
                                          <div className="discount">
                                            USD$ {USDollar.format(discountVal)}
                                          </div>
                                          <div className="discountText">
                                            {discountInfo}
                                          </div>{" "}
                                        </div>
                                        <div className="field col-12 md:col-6  ">
                                          <div className="subTotalWithDiscount">
                                            USD$ {USDollar.format(subTotal)}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {discountInfo === "" && (
                                      <div className="field col-12 md:col-6  ">
                                        <div className="subTotal">
                                          USD$ {USDollar.format(subTotal)}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="field col-12 md:col-12 linePaymentInfo"></div>
                                <div className="field col-12 md:col-4 totalInfo">
                                  <span>TOTAL</span>
                                </div>
                                <div className="field col-12 md:col-4 h1text ">
                                  <span className="">
                                    USD$ {USDollar.format(total)}
                                  </span>
                                </div>
                                <div className="field col-12 md:col-12 ">
                                  <Button
                                    label="Continuar"
                                    className="paymentInfoButton"
                                    icon={
                                      notification &&
                                      notification.status === "pending"
                                        ? "pi pi-spin pi-spinner"
                                        : ""
                                    }
                                    disabled={
                                      notification &&
                                      notification.status === "pending"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="field col-12 md:col-6 ">
                              <h2> {conference.informativeTitle}</h2>
                              <span className="grupalDiscuountInformation">
                                {conference.informativeBody}
                              </span>
                              <p className="descriptionConference ">
                                <p>
                                  En Aula ABA ampliamos nuestra su oferta con
                                  acceso ilimitado a más de 12,000 cursos en
                                  línea de primer nivel.
                                </p>
                                <p>
                                  Descubre una amplia variedad de proyectos
                                  prácticos y programas de certificación
                                  diseñados para prepararte para el mundo
                                  laboral, todo incluido en tu suscripción.
                                </p>
                                <p>
                                  Obtén acceso a videos de los cursos,
                                  especializaciones y certificados
                                  profesionales, impartidos por destacados
                                  instructores de universidades y empresas
                                  líderes.
                                </p>
                                <p>
                                  Acceso más de 12,000 cursos y certificaciones
                                  de universidades locales e internacionales
                                  reconocidas. Aprenderás habilidades y
                                  herramientas relevantes para el trabajo con
                                  más de 1000 proyectos aplicados y laboratorios
                                  prácticos de expertos de la industria.
                                </p>
                                <p>
                                  Disfrutarás de la máxima flexibilidad para
                                  lograr el equilibrio entre vida personal y
                                  laboral y aprender a su propio ritmo.
                                </p>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};
export default HomeContent;

import React from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Notification from "../components/UI/Notification";
import { GetConferenceByCode } from "../store/conferences-store/conference-actions";
import NavBarMenu from "./NavBarMenu";
import { validateImage, validateToken } from "../utils/Guard";
import Footer from "./Footer";

const BaseLayout = () => {
  const notification = useSelector((state) => state.ui.notification);
  const imageUrl = useSelector((state) => state.conference.ImageUrl);
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  const isValid = validateToken(params.conferenceCode, location.pathname);

  useEffect(() => {
    if (params.conferenceCode && !validateImage(params.conferenceCode)) {
      dispatch(GetConferenceByCode(params.conferenceCode));
    }
  }, [dispatch, imageUrl, params.conferenceCode]);

  useEffect(() => {
    if (
      !location.pathname.includes("home") &&
      !location.pathname.includes("orden") &&
      isValid
    ) {
      if (
        params.conferenceCode === null ||
        params.conferenceCode === undefined ||
        params.conferenceCode !== "ABACOURSERA"
      ) {
        window.location.href = "/home/ABACOURSERA";
      }
    }
  }, [isValid, location.pathname, params.conferenceCode]);

  return (
    <>
      {notification && (
        <Notification
          status={notification.status}
          title={notification.title}
          message={notification.message}
        />
      )}
      {!isValid && (
        <>
          <Outlet />
          <Footer marginTop="-40px" />
        </>
      )}
      {isValid && (
        <>
          <NavBarMenu />
          <Outlet />
          <Footer marginTop="210px" />
        </>
      )}
    </>
  );
};
export default BaseLayout;

import { companyActions } from "./company-slice";
import { uiActions } from "../ui-slice";

export const GetCompanies = (email) => {
  return async (dispatch) => {
    const GetCompaniesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/company/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la información solicitada");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetCompaniesInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          companyActions.setCompanies({
            companies: response.data
              .filter((company) => company.status === true)
              .map((company) => {
                return {
                  code: company.id,
                  name: company.name,
                };
              }),
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          companyActions.setCompanies({
            statues: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la información solicitada",
        })
      );
    }
  };
};

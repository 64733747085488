import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style

import { Link, useNavigate, useParams } from "react-router-dom";
import { userActions } from "../../../../store/user-store/user-slice";
import { RegisterUsuario } from "../../../../store/user-store/user-actions";

import "./register.css";
import { uiActions } from "../../../../store/ui-slice";
import { getImage } from "../../../../utils/Guard";

let restartPage = false;

const Register = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const isValid = useSelector((state) => state.user.userValidation);
  const user = useSelector((state) => state.user.user);
  const notification = useSelector((state) => state.ui.notification);
  const [autoFocus, setAutoFocus] = useState({
    email: false,
    password: false,
    repassword: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [reshowPassword, setReShowPassword] = useState(false);

  useEffect(() => {
    if (
      notification &&
      notification.status === "success" &&
      user.id &&
      user.id > 0 &&
      restartPage
    ) {
      dispatch(userActions.restartState());
      window.location.href = "/login/" + params.conferenceCode;
    }
  }, [dispatch, navigate, notification, params.conferenceCode, user.id]);

  useEffect(() => {
    if (autoFocus.email) {
      document.getElementById("email").focus();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if (autoFocus.password) {
      document.getElementById("password").focus();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if (autoFocus.repassword) {
      document.getElementById("repassword").focus();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [autoFocus.email, autoFocus.password, autoFocus.repassword]);

  const onChangeHandler = (id, value) => {
    dispatch(userActions.modifyPropertyValue({ id, value }));
    if (value !== "") {
      dispatch(userActions.setFieldValid({ id, value: true }));
    } else {
      dispatch(userActions.setFieldValid({ id, value: false }));
    }
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (user.email === "") {
      dispatch(userActions.setFieldValid({ id: "email", value: false }));
      setAutoFocus({ email: true });
    } else if (user.password === "") {
      dispatch(userActions.setFieldValid({ id: "password", value: false }));
      setAutoFocus({ password: true });
    } else if (user.repassword === "") {
      dispatch(userActions.setFieldValid({ id: "repassword", value: false }));
      setAutoFocus({ repassword: true });
    } else {
      if (user.password !== user.repassword) {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Contraseñas no coinciden",
            message: "Las contraseñas no coinciden, favor de revisar.",
          })
        );
        dispatch(userActions.setFieldValid({ id: "password", value: false }));
        setAutoFocus({ password: true });
      } else {
        const newUser = {
          email: user.email,
          password: user.password,
        };
        dispatch(RegisterUsuario(newUser, user.email, params.conferenceCode));
        restartPage = true;
      }
    }
  };
  const getFormErrorMessage = (id) => {
    return !isValid[id].isValid ? (
      <Message severity="warn" text={isValid[id].errorMessage} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };

  const header = (
    <img
      alt="Card"
      className="registerImage"
      src={getImage(params.conferenceCode)}
    />
  );
  return (
    <>
      <div className="card flex justify-content-center registerBody">
        <div className="formgrid grid ">
          <div className="field col-12 md:col-12">
            <Card className="registerCard">
              <div className="formgrid grid ">
                <div className="field col-12 md:col-12">{header}</div>
                <div className="field col-12 md:col-3"></div>
                <div className="field col-12 md:col-12 registerTitle">
                  <span className="registerTitleText">Crea tu cuenta</span>
                  <br />
                  <br />
                  <span className="registerTitleNewUser">
                    ¿Ya tienes una cuenta? &nbsp;
                    <Link
                      to={`/login/${params.conferenceCode}`}
                      style={{ textDecoration: "none", color: "#2755A9" }}
                    >
                      <span>Iniciar sesión</span>
                    </Link>
                  </span>
                </div>
                <div className="field col-12 md:col-12"></div>
                <form onSubmit={onSubmitHandler} name={params.conferenceCode}>
                  <div className="formgrid grid">
                    <div
                      className="field col-12 md:col-12"
                      style={{ marginBottom: "0px" }}
                    >
                      <InputText
                        className="registerEmailTextBox"
                        id="email"
                        value={user.email}
                        onChange={(e) =>
                          onChangeHandler("email", e.target.value)
                        }
                        placeholder="Email"
                      />
                      <div>{getFormErrorMessage("email")}</div>
                    </div>
                    <div
                      className="field col-12 md:col-12"
                      style={{ marginBottom: "0px" }}
                    >
                      <InputText
                        className="registerpasswordTextBox"
                        id="password"
                        value={user.password}
                        type={showPassword === false ? "password" : "text"}
                        onChange={(e) =>
                          onChangeHandler("password", e.target.value)
                        }
                        placeholder="Contraseña"
                      />
                      <Link
                        className="registershowPassword"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <i
                          className={
                            showPassword === false
                              ? "pi pi-eye"
                              : "pi  pi-eye-slash"
                          }
                        ></i>
                      </Link>

                      <div>{getFormErrorMessage("password")}</div>
                    </div>
                    <div
                      className="field col-12 md:col-12"
                      style={{ marginBottom: "0px" }}
                    >
                      <InputText
                        className="registerpasswordTextBox"
                        value={user.repassword}
                        type={reshowPassword === false ? "password" : "text"}
                        onChange={(e) =>
                          onChangeHandler("repassword", e.target.value)
                        }
                        placeholder="Repetir Contraseña"
                      />

                      <Link
                        className="registershowPassword"
                        onClick={() => {
                          setReShowPassword(!reshowPassword);
                        }}
                      >
                        <i
                          className={
                            reshowPassword === false
                              ? "pi pi-eye"
                              : "pi  pi-eye-slash"
                          }
                        ></i>
                      </Link>

                      <div>{getFormErrorMessage("repassword")}</div>
                    </div>
                  </div>
                  <Button
                    label="Crear cuenta"
                    className="registerButton"
                    icon={
                      notification && notification.status === "pending"
                        ? "pi pi-spin pi-spinner"
                        : ""
                    }
                    disabled={notification && notification.status === "pending"}
                  />
                </form>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;

import React from "react";
import masterCardLogo from "../assets/img/mastercard-logo.jpg";
import visaCardLogo from "../assets/img/Visa-Logo-2005.jpg";
import visaVerifiedLogo from "../assets/img/visa-secure_blu.png";
import masterCardIdentity from "../assets/img/mastercardidentitycheck.png";

import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const Footer = ({ marginTop }) => {
  const [visible, setVisible] = React.useState(false);
  const headerElement = <span>Políticas de Seguridad</span>;
  const footerContent = <div></div>;
  const content = (
    <div style={{ backgroundColor: "#FFFFFF", padding: "20px" }}>
      <h1 style={{ textAlign: "center" }}>Políticas de Seguridad</h1>
      <span style={{ fontWeight: "bold" }}>WEBSITE</span>
      <br />
      Tomamos todas las medidas y precauciones razonables para proteger tu
      información personal y seguimos las mejores prácticas de la industria para
      asegurar que tu información no sea utilizada de manera inapropiada,
      alterada o destruida. Ciframos la información de tu tarjeta de crédito
      utilizando la tecnología de capa de puertos seguros o Secur Sockets Layer
      (SSL), y la almacenamos con el cifrado AES-256. También, seguimos todos
      los requerimientos del PCI-DSS.
      <br />
      <br />
      <span style={{ fontWeight: "bold" }}>PAGOS</span>
      <br />
      Los métodos de pago utilizados por LA EMPRESA son servicios de terceros.
      Estos servicios de terceros (AZUL), cumplen con todos los estándares de
      seguridad y cifrado para mantener tu información segura. Solo utilizarán
      la información necesaria para completar el proceso requerido. También
      recomendamos leer las Políticas de Privacidad de estos proveedores, para
      entender mejor cómo manejan la información suministrada.
      <div style={{ marginTop: "10px" }}>
        <Button
          text
          type="button"
          label="cerrar"
          icon="pi pi-times"
          severity="danger"
          style={{ border: "none" }}
          onClick={() => {
            setVisible(false);
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <Dialog
        visible={visible}
        modal
        header={headerElement}
        content={content}
        style={{ width: "50rem", backgroundColor: "#FFFFFF" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      ></Dialog>

      <div
        className="grid"
        style={{ marginTop: marginTop, backgroundColor: "#FFFFFF" }}
      >
        <div className="col-2">
          <Image src={masterCardLogo} alt="mastercard-logo" width="45" />
        </div>
        <div className="col-2" style={{ marginTop: "0px" }}>
          <Image src={masterCardIdentity} alt="mastercard-logo" width="45" />
        </div>
        <div className="col-2" style={{ marginTop: "10px" }}>
          <Image src={visaCardLogo} alt="visa-logo" width="45" />
        </div>
        <div className="col-2" style={{ marginTop: "0px" }}>
          <Image src={visaVerifiedLogo} alt="visa-verified" width="45" />
        </div>
        <div className="col-4" style={{ marginTop: "0px" }}>
          <Button
            label="Políticas de Seguridad"
            link
            onClick={() => {
              setVisible(true);
            }}
          />
        </div>
      </div>
    </>
  );
};
export default Footer;

import React from "react";
import { Card } from "primereact/card";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  DiscountOrden,
  GetOrdensById,
  UpdateOrden,
} from "../../../store/orden-store/orden-actions";
import { getTokenInfo } from "../../../utils/Guard";
import Loading from "../../../components/UI/Loading";
import { GetConferenceByCode } from "../../../store/conferences-store/conference-actions";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { arregloPaises } from "../../../components/List";
import { Button } from "primereact/button";
import { uiActions } from "../../../store/ui-slice";
import "./OrdenPaymentInfo.css";
import { Dialog } from "primereact/dialog";

let restartPage = false;
const OrdenPaymentInfo = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const userInfo = getTokenInfo();
  const orden = useSelector((state) => state.orden.orden);
  const conference = useSelector((state) => state.conference.conference);
  const notification = useSelector((state) => state.ui.notification);

  const [ordenDiscount, setDiscount] = useState(0);
  const [selectTC, setSelectTC] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectName, setSelectName] = useState("");
  const [selectAddress, setSelectAddress] = useState("");
  const [selectEmail, setSelectEmail] = useState("");
  const [selectPhone, setSelectPhone] = useState("");
  const [selectRNC, setSelectRNC] = useState("");
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(1);
  const [discountCode, setDiscountCode] = useState("");
  const [isMediumSize, setIsMediumSize] = useState(false);
  const [isIpad, setIsIpad] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [count, setCount] = useState(0);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (orden && (orden.id === undefined || orden.id === 0)) {
      dispatch(GetOrdensById(userInfo.OrdenId, userInfo.Email));
    }
  }, [dispatch, orden, userInfo.Email, userInfo.OrdenId]);

  useEffect(() => {
    if (conference && (conference.id === undefined || conference.id === 0)) {
      dispatch(GetConferenceByCode(params.conferenceCode));
    }
  }, [conference, dispatch, params.conferenceCode]);

  useEffect(() => {
    if (
      orden &&
      (orden.id !== undefined || orden.id !== 0) &&
      orden.user &&
      (orden.user.id !== undefined || orden.user.id !== 0) &&
      selectedCountry === null &&
      selectName === "" &&
      selectAddress === "" &&
      selectEmail === "" &&
      selectPhone === "" &&
      selectRNC === ""
    ) {
      const pais = arregloPaises.find(
        (pais) => pais.nombre === orden.billCountry
      );
      setSelectedCountry({
        code: orden.billCountry,
        name: orden.billCountry,
        flag: pais !== undefined ? pais.abreviatura : "",
      });
      setSelectName(orden.billName);
      setSelectAddress(orden.billAddress);
      setSelectEmail(orden.billEmail);
      setSelectPhone(orden.billPhone);
      setSelectRNC(orden.rnc);

      if (orden.paymentMethodId === 2) {
        setSelectPaymentMethod(2);
        setSelectTC(false);
      } else {
        setSelectPaymentMethod(1);
        setSelectTC(true);
      }
      let counting = 0;
      orden.users.forEach((user) => {
        if (user.id !== 0) {
          counting++;
        }
      });
      setCount(counting);
    }
  }, [
    orden,
    selectAddress,
    selectEmail,
    selectName,
    selectPhone,
    selectRNC,
    selectedCountry,
  ]);

  useEffect(() => {
    if (orden && (orden.id !== undefined || orden.id !== 0)) {
      if (
        orden.discount &&
        orden.discount.id !== undefined &&
        orden.discount.id !== 0 &&
        ordenDiscount === 0 &&
        count > 0
      ) {
        if (!orden.discount.grupal) {
          if (orden.discount.percentage) {
            setDiscount(
              conference.price *
                count *
                (orden.discount.discountPercentage / 100)
            );
          }
          if (orden.discount.nominal) {
            setDiscount(orden.discount.discountNominal * count);
          }
          setDiscountCode(orden.discountCode);
        } else if (orden.discount.grupal && count >= 3) {
          if (orden.discount.percentage) {
            setDiscount(
              conference.price *
                count *
                (orden.discount.discountPercentage / 100)
            );
          }
          if (orden.discount.nominal) {
            setDiscount(orden.discount.discountNominal);
          }
          setDiscountCode(orden.discountCode);
        }
      }
    }
  }, [conference.price, orden, ordenDiscount, count]);

  useEffect(() => {
    if (
      orden &&
      orden.id !== undefined &&
      orden.id > 0 &&
      orden.paymentMethodId > 0 &&
      restartPage
    ) {
      if (orden.paymentMethodId === 1) {
        restartPage = false;
        window.location.href = "/orden/paytc/" + params.conferenceCode;
      } else if (orden.paymentMethodId === 2) {
        restartPage = false;
        window.location.href = "/orden/paytf/" + params.conferenceCode;
      }
    }
  }, [notification, params.conferenceCode, orden.paymentMethodId]);

  useEffect(() => {
    if (window.innerWidth <= 430 && !isMobile) {
      setIsMobile(true);
    }
    if (window.innerWidth >= 965 && window.innerWidth <= 984 && !isMediumSize) {
      setIsMediumSize(true);
    }
    if (window.innerWidth >= 768 && window.innerWidth <= 964 && !isMediumSize) {
      setIsIpad(true);
    }
  }, [isMediumSize, isMobile, isIpad]);
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const paises = arregloPaises.map((pais) => ({
    code: pais.nombre,
    name: pais.nombre,
    flag: pais.abreviatura,
  }));
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src={`https://flagcdn.com/48x36/${option.flag.toLowerCase()}.png`}
            style={{ width: "18px" }}
          />

          <div style={{ marginLeft: "5%" }}>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center" style={{ width: "100px" }}>
        <img
          alt={option.name}
          src={`https://flagcdn.com/48x36/${option.flag.toLowerCase()}.png`}
          style={{ width: "18px" }}
        />
        <div style={{ marginLeft: "5%" }}>{option.name}</div>
      </div>
    );
  };

  const onSubmitHandler = () => {
    if (selectName === "") {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "El nombre de facturación es requerido",
        })
      );
      return;
    } else if (selectAddress === "") {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "La dirección de facturación es requerida",
        })
      );
      return;
    } else if (selectedCountry === null) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "El país de facturación es requerido",
        })
      );
      return;
    } else if (selectEmail === "") {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "El correo de facturación es requerido",
        })
      );
      return;
    } else if (selectPhone === "") {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "El teléfono de facturación es requerido",
        })
      );
      return;
    } else if (acceptTerms === false) {
      setVisible(true);
      return;
    } else {
      const ordenUpdate = {
        ordenId: orden.id,
        billPhone: selectPhone,
        billName: selectName,
        billAddress: selectAddress,
        billEmail: selectEmail,
        billCountry: selectedCountry.code,
        paymentMethodId: selectPaymentMethod,
        ternmConditions: acceptTerms,
      };
      setShowNotification(true);
      dispatch(UpdateOrden(ordenUpdate, userInfo.Email));
      restartPage = true;
    }
  };
  const addDiscount = () => {
    dispatch(DiscountOrden(orden.id, userInfo.Email, discountCode));
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  let discountInfo = "";
  if (
    orden.discount &&
    orden.discount.id > 0 &&
    orden.discount.nominal === true
  ) {
    if (!orden.discount.grupal) {
      discountInfo =
        "Descuento de " +
        USDollar.format(orden.discount.discountNominal) +
        " por inscrito";
    } else if (orden.discount.grupal && count >= 3) {
      discountInfo =
        "Descuento de " +
        USDollar.format(orden.discount.discountNominal) +
        " por grupo de 3 o más inscritos";
    }
  }
  if (
    orden.discount &&
    orden.discount.id > 0 &&
    orden.discount.percentage === true
  ) {
    if (!orden.discount.grupal) {
      discountInfo =
        "Descuento de " + orden.discount.discountPercentage + "% por inscrito";
    } else if (orden.discount.grupal && count >= 3) {
      discountInfo =
        "Descuento de " +
        orden.discount.discountPercentage +
        "% por grupo de 3 o más inscritos";
    }
  }

  const headerElement = (
    <span style={{ color: "#19366C" }} className="ordenDetailShowModalFont">
      No. Orden: {orden.id}
    </span>
  );

  const footerContent = (
    <div className="formgrid grid">
      {!isMobile && (
        <div className="field col-12 md:col-12 mt-4">
          <Button
            text
            raised
            type="button"
            label="No Aceptar"
            style={{ width: "100%" }}
            onClick={() => {
              setAcceptTerms(false);
              setVisible(false);
            }}
            className="addParticipant"
          />
          <Button
            text
            raised
            type="button"
            label="Aceptar"
            onClick={() => {
              setVisible(false);
              setAcceptTerms(true);
            }}
            className="ordenDetailCheckoutButton"
          />
        </div>
      )}
      {isMobile && (
        <>
          <div className="field col-12 md:col-12 ">
            <Button
              text
              raised
              type="button"
              label="No Aceptar"
              style={{ width: "100%" }}
              onClick={() => {
                setAcceptTerms(false);
                setVisible(false);
              }}
              className="completParticipant"
            />
          </div>
          <div className="field col-12 md:col-12 ">
            <Button
              text
              raised
              type="button"
              label="Aceptar"
              onClick={() => {
                setVisible(false);
                setAcceptTerms(true);
              }}
              className="ordenDetailCheckoutButton"
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      {showNotification && <Loading visible={showNotification} />}
      <Dialog
        visible={visible}
        modal
        header={headerElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <p
          style={{
            textAlign: "justify",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <p>
            Términos y Condiciones de la Suscripción Al adquirir presente
            suscripción de acceso a la plataforma de capacitación Coursera
            mediante, usted acepta y reconoce los siguientes términos y
            condiciones:
          </p>
          <ol>
            <li>
              Duración de la Licencia:
              <ul>
                <li>
                  La licencia de acceso a la plataforma se habilitará por un
                  período de un (1) año, comenzando el 20 de febrero de 2025 y
                  finalizando el 20 de febrero de 2026.
                </li>
              </ul>
            </li>
            <br />
            <li>
              Costo y Pago:
              <ul>
                <li>El costo total de la suscripción es de USD $199.99.</li>
              </ul>
              <ul>
                <li>Una vez realizado el pago, este es no reembolsable.</li>
              </ul>
            </li>
            <br />
            <li>
              Acceso y Uso:
              <ul>
                <li> El acceso a la cuenta es personal.</li>
              </ul>
              <ul>
                <li>
                  Usted es responsable de mantener la confidencialidad de su
                  información de inicio de sesión y de todas las actividades que
                  ocurran bajo su cuenta.
                </li>
              </ul>
            </li>
            <br />
            <li>
              Términos y Condiciones de Coursera:
              <ul>
                <li>
                  Al utilizar la plataforma, usted también acepta los Términos y
                  Condiciones de Coursera, los cuales pueden ser consultados en
                  el siguiente enlace: https://www.coursera.org/about/terms.
                </li>
                <li>
                  Es su responsabilidad leer y comprender los términos y
                  condiciones de Coursera, ya que estos rigen el uso de sus
                  servicios y contenidos.
                </li>
              </ul>
            </li>
            <br />
            <li>
              Propiedad Intelectual:
              <ul>
                <li>
                  Todos los contenidos disponibles en la plataforma están
                  protegidos por las leyes de propiedad intelectual y son
                  propiedad exclusiva de la empresa o de sus licenciantes.
                </li>
                <li>
                  Queda prohibida la reproducción, distribución o divulgación de
                  los contenidos de la plataforma sin autorización previa y por
                  escrito.
                </li>
              </ul>
            </li>
            <br />
            <li>
              Responsabilidad:
              <ul>
                <li>
                  La ABA no se hace responsable por los certificados emitidos de
                  los cursos realizados, siendo los centros de estudios que
                  ofrecen sus cursos y Coursera los únicos responsables de la
                  emisión y validez de dichos certificados.
                </li>
                <li>
                  La empresa no se hace responsable por interrupciones en el
                  servicio debido a causas fuera de su control, como fallas
                  técnicas o mantenimiento programado.
                </li>
                <li>
                  No nos responsabilizamos por el uso indebido de la plataforma
                  o por incumplimientos de los términos y condiciones de
                  Coursera.
                </li>
              </ul>
            </li>
            <br />
            <li>
              Modificaciones:
              <ul>
                <li>
                  La empresa se reserva el derecho de modificar estos términos y
                  condiciones en cualquier momento.
                </li>
                <li>
                  Cualquier cambio será notificado a los usuarios a través de
                  los medios de contacto proporcionados.
                </li>
                <li>
                  Continuar utilizando la plataforma después de la notificación
                  de cambios implica la aceptación de los nuevos términos y
                  condiciones.
                </li>
              </ul>
            </li>
            <br />
            <li>
              Aceptación de los Términos:
              <ul>
                <li>
                  Al completar el proceso de compra, usted declara haber leído y
                  aceptado todos los términos y condiciones aquí establecidos,
                  así como los términos y condiciones de Coursera.
                </li>
                <li>
                  Si tiene alguna pregunta o requiere asistencia adicional, por
                  favor, comuníquese con nuestro equipo de soporte al cliente
                  antes de finalizar su compra.
                </li>
              </ul>
            </li>
          </ol>
          Este aviso está diseñado para garantizar que usted esté plenamente
          informado sobre los términos de su suscripción. Le recomendamos leer
          detenidamente este documento y los términos y condiciones de Coursera
          antes de proceder con la compra.
        </p>
      </Dialog>
      <div className="ordenPaymentInfoHeader">
        <div className="ordenPaymentInfoTitle">
          <span>Información de pago</span>
        </div>
      </div>
      {orden && (orden.id === undefined || orden.id === 0) && <Loading />}
      {orden && orden.users && count > 0 && (
        <>
          <div className="card flex justify-content-center ">
            <div className="formgrid grid">
              <div className="field col-12 md:col-6">
                <Card className="ordenPaymentInfoBox">
                  <div className="formgrid grid">
                    <div className="field col-12 md:col-12 ">
                      <span className="ordenPaymentInfoBill ordenPaymentInfoSpace">
                        Datos de facturación
                      </span>
                      <p className="ordenPaymentInfoBillText ">
                        El nombre de la empresa que seleccione, aparecerá en la
                        factura emitida por nuestro equipo de Finanzas.
                        Asegúrese de que el nombre y la dirección de la empresa
                        sean correctos antes de completar su inscripción.
                      </p>
                    </div>
                    <div className="field col-12 md:col-12">
                      <label
                        htmlFor="name"
                        className="ordenPaymentInfoBillInfoText"
                      >
                        Facturar a nombre de (colocar empresa o persona física):
                      </label>
                      <InputText
                        id="name"
                        value={selectName}
                        onChange={(e) => {
                          setSelectName(e.target.value);
                        }}
                        placeholder="Nombre"
                        className="ordenPaymentInfoBillInfoInput"
                      />
                    </div>
                    <div className="field col-12 md:col-12 ordenPaymentInfoSpace">
                      <label
                        htmlFor="address"
                        className="ordenPaymentInfoBillInfoText"
                      >
                        Dirección
                      </label>
                      <InputText
                        id="address"
                        value={selectAddress}
                        onChange={(e) => {
                          setSelectAddress(e.target.value);
                        }}
                        placeholder="Dirección"
                        className="ordenPaymentInfoBillInfoInput"
                      />
                    </div>
                    <div className="field col-12 md:col-12 ordenPaymentInfoSpace">
                      <label
                        htmlFor="country"
                        className="ordenPaymentInfoBillInfoText"
                      >
                        País
                      </label>
                      <Dropdown
                        value={selectedCountry}
                        onChange={(e) => {
                          setSelectedCountry({
                            code: e.value.code,
                            name: e.value.name,
                            flag: e.value.flag,
                          });
                        }}
                        options={paises}
                        optionLabel="name"
                        placeholder="Seleccionar pais"
                        valueTemplate={selectedCountryTemplate}
                        filter
                        itemTemplate={countryOptionTemplate}
                        className="userFormDetailDropdown"
                      />
                    </div>
                    <div className="field col-12 md:col-12 ordenPaymentInfoSpace">
                      <label
                        htmlFor="email"
                        className="ordenPaymentInfoBillInfoText"
                      >
                        Correo de Contacto de facturación
                      </label>
                      <InputText
                        id="email"
                        value={selectEmail}
                        onChange={(e) => {
                          setSelectEmail(e.target.value);
                        }}
                        placeholder="Correo"
                        className="ordenPaymentInfoBillInfoInput"
                      />
                    </div>
                    <div className="field col-12 md:col-12 ordenPaymentInfoSpace">
                      <label
                        htmlFor="phone"
                        className="ordenPaymentInfoBillInfoText"
                      >
                        Teléfono de contacto de facturación
                      </label>
                      <InputText
                        id="phone"
                        value={selectPhone}
                        onChange={(e) => {
                          setSelectPhone(e.target.value);
                        }}
                        placeholder="Teléfono"
                        className="ordenPaymentInfoBillInfoInput"
                      />
                    </div>
                    <div className="field col-12 md:col-12 ordenPaymentInfoSpace">
                      <label className="ordenPaymentInfoBillInfoText">
                        Esta persona será el punto de contacto para cualquier
                        consulta sobre pagos.
                      </label>
                    </div>
                    <div className="field col-12 md:col-12 ordenPaymentInfoSpace">
                      <label
                        htmlFor="rnc"
                        className="ordenPaymentInfoBillInfoText"
                      >
                        RNC (solo aplica para República Dóminicana)
                      </label>
                      <InputText
                        id="rnc"
                        value={selectRNC}
                        onChange={(e) => {
                          setSelectRNC(e.target.value);
                        }}
                        placeholder="RNC"
                        className="ordenPaymentInfoBillInfoInput"
                      />
                    </div>
                    {!isMobile && (
                      <div className="field col-12 md:col-12 ordenPaymentInfoSpace">
                        <Card className="ordenPaymentInfoPaymentMethodBox">
                          <div className="formgrid grid">
                            <div className="field col-12 md:col-12 ">
                              <div className="ordenPaymentInfoPaymentMethodHeader">
                                Método de pago
                              </div>
                            </div>
                            {!isMediumSize && !isIpad && (
                              <div className="field col-12 md:col-4 "></div>
                            )}

                            <div className="field col-12 md:col-4 ">
                              <Button
                                text
                                raised
                                type="button"
                                label="Tarjeta de crédito"
                                onClick={() => {
                                  setSelectTC(true);
                                  setSelectPaymentMethod(1);
                                  //
                                }}
                                className={
                                  selectTC
                                    ? "ordenPaymentInfoButtonTC"
                                    : "ordenPaymentInfoButtonTD"
                                }
                              />
                            </div>
                            {(isMediumSize || isIpad) && (
                              <div className="field col-12 md:col-2 ordenPaymentInfoButtonSpace"></div>
                            )}
                            {!conference.creditCardOnly && (
                              <div className="field col-12 md:col-4 ordenPaymentInfoButtonSpace">
                                <Button
                                  text
                                  raised
                                  type="button"
                                  label="Transferencia"
                                  onClick={() => {
                                    setSelectTC(false);
                                    setSelectPaymentMethod(2);
                                  }}
                                  className={
                                    !selectTC
                                      ? "ordenPaymentInfoButtonTC"
                                      : "ordenPaymentInfoButtonTD"
                                  }
                                />
                              </div>
                            )}
                            <div className="field col-12 md:col-12 ordenPaymentInfoSpace">
                              <Card className="ordenPaymentInfoPaymentMethodTypeBox">
                                <div className="formgrid grid">
                                  <div className="field col-12 md:col-12 ">
                                    {selectTC && (
                                      <>
                                        <p className="ordenPaymentInfoPaymentMethodInfoTC">
                                          Al seleccionar esta opción, serás
                                          redirigido a la página de Azul. Aunque
                                          el monto se mostrará en peso
                                          dominicano,{" "}
                                          <span className="ordenPaymentInfoPaymentMethodInfoTCStrong">
                                            el procesamiento del pago se
                                            realizará en dólares estadounidenses
                                            para los inscritos internacionales.
                                          </span>
                                        </p>
                                        <span className="ordenPaymentInfoPaymentMethodInfoTC">
                                          Para cualquier otro problema con los
                                          pagos, por favor contáctenos en{" "}
                                          <span className="ordenPaymentInfoPaymentMethodInfoTCContact">
                                            infoaula@aba.org.do
                                          </span>
                                        </span>
                                      </>
                                    )}
                                    {!selectTC && (
                                      <>
                                        <p className="ordenPaymentInfoPaymentMethodInfoTC">
                                          Realiza el pago directamente en
                                          nuestra cuenta bancaria. Utiliza tu
                                          número de órden como referencia de
                                          pago. Su cupo será reservado por un
                                          plazo de{" "}
                                          <span className="ordenPaymentInfoPaymentMethodInfoTCContact">
                                            48
                                          </span>{" "}
                                          {""}
                                          horas.
                                        </p>
                                      </>
                                    )}
                                  </div>
                                  <div className="field col-12 md:col-12">
                                    <Button
                                      text
                                      raised
                                      type="button"
                                      label="Continuar al pago"
                                      className="ordenPaymentInfoPaymentMethodContinueToPayment"
                                      onClick={() => {
                                        onSubmitHandler();
                                      }}
                                    />
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </div>
                        </Card>
                      </div>
                    )}
                  </div>
                  {isMobile && (
                    <div className="field col-12 md:col-12">
                      <Card className="ordenPaymentInfoResumeBox">
                        <div className="formgrid grid">
                          <div className="field col-12 md:col-2 ">
                            <div className="ordenPaymentInfoParticipantCount">
                              {count}
                            </div>
                          </div>
                          <div className="field col-12 md:col-8 ">
                            <div className="ordenPaymentInfoPriceLessDiscount">
                              US
                              {USDollar.format(conference.price * count)} - US
                              {USDollar.format(ordenDiscount)}
                            </div>
                            <span className="discountText">{discountInfo}</span>{" "}
                          </div>
                          <div className="field col-12 md:col-2 ">
                            <div className="ordenPaymentInfoResult">
                              {USDollar.format(
                                conference.price * count - ordenDiscount
                              )}
                            </div>
                          </div>
                          <div className="field col-12 md:col-12 ">
                            <div className="ordenPaymentInfoLine"></div>
                          </div>
                          <div className="field col-12 md:col-6 ">
                            <div className="ordenPaymentInfoTotalText">
                              Total{" "}
                              {USDollar.format(
                                conference.price * count - ordenDiscount
                              )}
                            </div>
                          </div>

                          <div className="field col-12 md:col-12">
                            <InputText
                              className="ordenPaymentInfoDiscountInpunt"
                              id="discountCode"
                              value={discountCode}
                              onChange={(e) => {
                                setDiscountCode(e.target.value);
                              }}
                              placeholder="Agregar código de descuento"
                            />

                            <Button
                              type="button"
                              className="ordenPaymentInfoDiscountButton"
                              label="Agregar"
                              onClick={() => {
                                addDiscount();
                              }}
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                </Card>
              </div>
              {!isMobile && (
                <div className="field col-12 md:col-6">
                  <Card className="ordenPaymentInfoResumeBox">
                    <div className="formgrid grid">
                      <div className="field col-12 md:col-2 ">
                        <div className="ordenPaymentInfoParticipantCount">
                          {count}
                        </div>
                      </div>
                      <div className="field col-12 md:col-8 ">
                        <div className="ordenPaymentInfoPriceLessDiscount">
                          US
                          {USDollar.format(conference.price * count)} - US
                          {USDollar.format(ordenDiscount)}
                        </div>
                        <span className="discountText">{discountInfo}</span>{" "}
                      </div>
                      <div className="field col-12 md:col-2 ">
                        <div className="ordenPaymentInfoResult">
                          {USDollar.format(
                            conference.price * count - ordenDiscount
                          )}
                        </div>
                      </div>
                      <div className="field col-12 md:col-12 ">
                        <div className="ordenPaymentInfoLine"></div>
                      </div>
                      <div className="field col-12 md:col-6 ">
                        <div className="ordenPaymentInfoTotalText">Total</div>
                      </div>
                      <div className="field col-12 md:col-6 ">
                        <div className="ordenPaymentInfoTotalText">
                          {USDollar.format(
                            conference.price * count - ordenDiscount
                          )}
                        </div>
                      </div>

                      <div className="field col-12 md:col-12">
                        <InputText
                          className="ordenPaymentInfoDiscountInpunt"
                          id="discountCode"
                          value={discountCode}
                          onChange={(e) => {
                            setDiscountCode(e.target.value);
                          }}
                          placeholder="Agregar código de descuento"
                          // disabled={orden.discount.id > 0}
                        />

                        <Button
                          type="button"
                          className="ordenPaymentInfoDiscountButton"
                          label="Agregar"
                          // disabled={orden.discount.id > 0}
                          onClick={() => {
                            addDiscount();
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              {isMobile && (
                <div className="field col-12 md:col-12 ordenPaymentInfoSpace">
                  <Card className="ordenPaymentInfoPaymentMethodBox">
                    <div className="formgrid grid">
                      <div className="field col-12 md:col-12 ">
                        <div className="ordenPaymentInfoPaymentMethodHeader">
                          Método de pago
                        </div>
                      </div>
                      <div className="field col-12 md:col-12 ordenPaymentInfoButtonSpace">
                        <Button
                          text
                          raised
                          type="button"
                          label="Tarjeta de crédito"
                          onClick={() => {
                            setSelectTC(true);
                            setSelectPaymentMethod(1);
                            //
                          }}
                          className={
                            selectTC
                              ? "ordenPaymentInfoButtonTC"
                              : "ordenPaymentInfoButtonTD"
                          }
                        />
                      </div>
                      {!conference.creditCardOnly && (
                        <div className="field col-12 md:col-12 ">
                          <Button
                            text
                            raised
                            type="button"
                            label="Transferencia"
                            onClick={() => {
                              setSelectTC(false);
                              setSelectPaymentMethod(2);
                            }}
                            className={
                              !selectTC
                                ? "ordenPaymentInfoButtonTC"
                                : "ordenPaymentInfoButtonTD"
                            }
                          />
                        </div>
                      )}
                      <div className="field col-12 md:col-12 ordenPaymentInfoSpace">
                        <Card className="ordenPaymentInfoPaymentMethodTypeBox">
                          <div className="formgrid grid">
                            <div className="field col-12 md:col-12 ">
                              {selectTC && (
                                <>
                                  <p className="ordenPaymentInfoPaymentMethodInfoTC">
                                    Al seleccionar esta opción, serás redirigido
                                    a la página de Azul. Aunque el monto se
                                    mostrará en peso dominicano,{" "}
                                    <span className="ordenPaymentInfoPaymentMethodInfoTCStrong">
                                      el procesamiento del pago se realizará en
                                      dólares estadounidenses para los inscritos
                                      internacionales.
                                    </span>
                                  </p>
                                  <span className="ordenPaymentInfoPaymentMethodInfoTC">
                                    Para cualquier otro problema con los pagos,
                                    por favor contáctenos en{" "}
                                    <span className="ordenPaymentInfoPaymentMethodInfoTCContact">
                                      infoaula@aba.org.do
                                    </span>
                                  </span>
                                </>
                              )}
                              {!selectTC && (
                                <>
                                  <p className="ordenPaymentInfoPaymentMethodInfoTC">
                                    Realiza el pago directamente en nuestra
                                    cuenta bancaria. Utiliza tu número de órden
                                    como referencia de pago. Su cupo será
                                    reservado por un plazo de{" "}
                                    <span className="ordenPaymentInfoPaymentMethodInfoTCContact">
                                      15
                                    </span>{" "}
                                    {""}
                                    días.
                                  </p>
                                </>
                              )}
                            </div>
                            <div className="field col-12 md:col-12">
                              <Button
                                text
                                raised
                                type="button"
                                label="Continuar al pago"
                                className="ordenPaymentInfoPaymentMethodContinueToPayment"
                                onClick={() => {
                                  onSubmitHandler();
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default OrdenPaymentInfo;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ordens: [],
  orden: {
    ordenId: 0,
    usuarioId: "",
    certificado: "",
    entero: "",
    tipo: "",
    origen: "",
    cantidad: 0,
    comprobante: false,
    rnc: "",
    facturaFiscal: false,
    monto: 0.0,
    tasa: 0.0,
    extraActivity: false,
    ternmConditions: false,

    metodoPago: {
      descripcion: "",
    },
    estado: {
      descripcion: "",
    },
    ordenType: {
      descripcion: "",
    },
    discount: {
      discountCode: "",
      percentage: false,
      discountPercentage: 0.0,
      nominal: false,
      discountNominal: 0.0,
      startDate: new Date().toDateString(),
    },
    usuarios: [],
    fecha: new Date().toDateString(),
    fechaOrden: new Date().toDateString(),
  },

  transaction: {},
};

const ordenSlice = createSlice({
  name: "Orden",
  initialState,
  reducers: {
    restartState(state) {
      state.orden = {};
      state.ordens = [];
    },
    setOrdens(state, actions) {
      state.ordens = actions.payload.ordens;
      state.orden = {};
    },
    setOrden(state, actions) {
      state.orden = actions.payload.orden;
      state.ordens = [];
    },
    setTransaction(state, actions) {
      state.transaction = actions.payload.transaction;
    },
    modifyPropertyValue(state, actions) {
      state.orden[actions.payload.id] = actions.payload.orden;
    },
  },
});

export const ordenActions = ordenSlice.actions;
export default ordenSlice;

import React from "react";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import UserMenu from "./UserMenu";

import "./NavBar.css";
import { getImage } from "../utils/Guard";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const NavBar = (props) => {
  const params = useParams();
  const items = [];
  const conference = useSelector((state) => state.conference.conference);

  if (
    conference &&
    conference.conferenceMenuInfoes &&
    conference.conferenceMenuInfoes.length > 0
  ) {
    conference.conferenceMenuInfoes.forEach((menu) => {
      items.push({
        template: () => {
          return (
            <Button
              label={menu.name}
              className="NavBarItem"
              onClick={() => {
                window.open(menu.url, "_blank");
              }}
            />
          );
        },
      });
    });
  }

  const start = (
    <>
      <img
        className="NavBarImage"
        alt="logo"
        src={getImage(params.conferenceCode)}
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location.href = "/home/" + params.conferenceCode;
        }}
      ></img>
    </>
  );
  const end = (
    <div className="NavBarButtons">
      <UserMenu
        userName={props.userName}
        ordens={props.ordens}
        changePassword={props.changePassword}
        logoutHandler={props.logoutHandler}
      />
    </div>
  );

  return (
    <>
      <Menubar model={items} start={start} end={end} className="NavBar" />
    </>
  );
};
export default NavBar;

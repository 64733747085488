import { createSlice } from "@reduxjs/toolkit";
import { getCurrentDate } from "../../utils/CurrentDate";

const initialState = {
  discounts: [],
  discount: {
    id: 0,
    idCongreso: 0,
    idCourse: "",
    description: "",
    name: "",
    discountCode: "",
    percentage: false,
    discountPercentage: 0.0,
    nominal: false,
    discountNominal: 0.0,
    numberOfUses: 0,
    grupal: false,
  },
};

const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    restartState(state) {
      state.discount = {};
      state.discounts = [];
    },
    setDiscounts(state, actions) {
      state.discounts = actions.payload.discounts;
      state.discount = {};
    },
    setDiscounts(state, actions) {
      state.discounts = actions.payload.discounts;
      state.discount = {};
    },
    modifyPropertyValue(state, actions) {
      state.discount[actions.payload.id] = actions.payload.value;
    },
  },
});

export const discountActions = discountSlice.actions;
export default discountSlice;

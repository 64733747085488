import { departmentActions } from "./department-slice";

export const GetDepartments = (email) => {
  return async (dispatch) => {
    const GetDepartmentsInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/department/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la información solicitada");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetDepartmentsInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          departmentActions.setDepartments({
            departments: response.data
              .filter((department) => department.status === true)
              .map((department) => {
                return {
                  code: department.id,
                  name: department.name,
                };
              }),
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          departmentActions.setDepartments({
            statues: [],
          })
        );
      }
    } catch (error) {}
  };
};
